<template>
  <div class="main container product-page">
    <el-backtop :right="100" :bottom="180"></el-backtop>
    <Breadcrumb :list="breadcrumbList" />
    <div class="content">
      <div class="left">
        <h2 class="title">应用场景</h2>
        <section class="animate__animated animate__fadeIn">
          <h3 class="sub-title">⭐关系型缓存</h3>
          <p>
            ShuangzhaoDb是一款 NewSQL产品，采用内存存储和Share-nothing架构。
            既继承了传统关系型的优点，如关系型存储、支持标准SQL、满足ACID等，
            又具有高可用、水平扩展的特性。可在低配服务器集群上实现百万TPS和亚毫秒级延时，
            不仅可以作为通用数据库支撑高频交易场景，也很适合用作高速缓存系统。是关系型缓存的最优选择。
          </p>
          <p>ShuangzhaoDB 关系型缓存可以解决 Redis 缓存的以下问题：</p>
          <ul class="redis-problems">
            <li><span class="point">▲</span>缓存命中率低</li>
            <li><span class="point">▲</span>分布式能力弱</li>
            <li><span class="point">▲</span>编码复杂</li>
            <li><span class="point">▲</span>持久化影响性能</li>
          </ul>
        </section>
      </div>
      <ProdMenu class="right hidden-sm-and-down" activeIndex="3" />
    </div>
    <div class="animate__animated animate__fadeIn">
      <section>
        <p>使用ShuangzhaoDb缓存的两种方式：</p>
        <h4 class="sub-title1">1. 原生API接入ShuangzhaoDB</h4>
        <p>
          常见的缓存系统可归类为四种读写模式：Cache aside，Read through，Write
          through，Write behind caching. Redis 缓存通常采用 Cache
          aside，即：当缓存失效或未命中时，读取数据库并写入缓存。
          当需要写入数据时，同时写入缓存和数据库。 Cache aside
          由业务端来保证缓存与业务数据库的数据一致性，逻辑复杂。如下图
        </p>
        <el-image
          :src="require('@/assets/images/application-scene6.png')"
          alt="关系型缓存"
          fit="cover"
          :preview-src-list="previewPicList"
        />
        <div style="margin-top: 16px">
          <p>
            ShuangzhaoDB 同样支持 Cache aside，但更推荐 Write behind caching
            模式，即：ShuangzhaoDB
            中全量持有交易热数据，全面接管业务应用对热数据的读写操作， 由
            ShuangzhaoDB
            负责完成业务数据与后台业务数据库的同步（一键式配置）。如下图：
          </p>
        </div>
        <el-image
          :src="require('@/assets/images/application-scene7.png')"
          alt="关系型缓存"
          fit="cover"
          :preview-src-list="previewPicList"
        />
        <p class="tips">
          注：要使用这种方式 ，需要业务应用使用ShuangzhaoDB的 Client API 接入
          ShuangzhaoDB。
        </p>
        <p>
          以上模式中，缓存数据以 ShuangzhaoDB 为主，业务端仅与 ShuangzhaoDB
          交互，业务代码不需要考虑数据一致性问题，可避免缓存穿透、击穿和雪崩。
        </p>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Cache aside</th>
              <th>Write behind caching</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="white-space: nowrap">失效</td>
              <td>
                应用程序先从 cache取数据，没有得到，则从数据库中取数据，
                成功后，放到缓存中。
              </td>
              <td>
                不存在失效，所有需要快速查询的数据（某时间段内或部分业务表的全量数据）都保存到
                ShuangzhaoDB 中。
              </td>
            </tr>
            <tr>
              <td>命中</td>
              <td>应用程序从 cache 中取数据，取到后返回。</td>
              <td>应用程序从 cache 中查询、分析和聚合数据，将计算结果返回。</td>
            </tr>
            <tr>
              <td>更新</td>
              <td>先把数据存到数据库中，成功后，再让缓存失效。</td>
              <td>
                当有数据更新的时候，直接写入缓存，然后返回。ACID
                特性可保证数据写入的绝对正确性和安全
                性。最后，缓存中的数据异步保存到全量数据库中。
              </td>
            </tr>
          </tbody>
        </table>
        <div class="optional-component">
          <h4 class="sub-title1">2. ShineRDSProxy</h4>
          <p>
            在现有的使用 Redis 缓存的生产系统中，业务应用使用 Redis 客户端，通过
            RESP 协 议接入 Redis 缓存系统。
            为了在不影响业务应用的情况下无缝迁移缓存系统，我们提供了
            ShineRDSProxy 组件。
          </p>
          <p>
            ShineRDSProxy 是将 ShuangzhaoDB 用于构建关系型缓存系统时的可选组件。
            ShuangzhaoDB 通过该组件向业务侧提供 Redis
            协议接口。保证存量系统不改动任何代码进行 Redis 到 ShuangzhaoDB
            的平迁，存量数据通过数据迁移工具提前初始化。 将 ShuangzhaoDB 与
            ShineRDSProxy 组件合并部署后， 即可将原有基于 Redis
            缓存接口的业务应用无缝迁移到 ShuangzhaoDB 缓存方案。
            按照该方案部署，整系统继承了ShuangzhaoDB的持久化和自动扩展能力，针对性解决了依赖Redis缓存的业务系统的痛点。
            (新建业务系统推荐使用 ShuangzhaoDB Client API
            进行缓存读写，以获取更高性能。)
          </p>
          <p>
            使用该组件提供的缓存系统，因为兼容原Redis缓存方案的kv存储，所以不能充分发挥ShuangzhaoDB行存储的优势。对于不同类型的Redis
            value的存取性能指标，与Redis相比有不同的出入。
            建议采用该方案的业务系统，
            <span style="color: #d04229; font-weight: 700">
              针对业务特点，由双照解决方案专家参与制定优化方案。
            </span>
          </p>
        </div>
      </section>
      <section
        v-for="item in sceneList"
        :key="item.id"
        class="animate__animated animate__fadeIn"
      >
        <h3 class="sub-title">{{ item.title }}</h3>
        <p>{{ item.content }}</p>
        <el-image
          v-if="item.imgUrl"
          :src="item.imgUrl"
          :alt="item.title"
          fit="cover"
          :preview-src-list="previewPicList"
        />
      </section>
      <section v-for="(row, i) in extraList" :key="row.id">
        <div class="marketing" v-if="i % 2">
          <img :src="row.imgUrl" />
          <ul>
            <li v-for="item in row.list" :key="item.id">
              <i class="icon" :class="item.icon" />{{ item.content }}
            </li>
          </ul>
        </div>
        <div class="marketing" v-else>
          <ul>
            <li v-for="item in row.list" :key="item.id">
              <i class="icon" :class="item.icon" />{{ item.content }}
            </li>
          </ul>
          <img :src="row.imgUrl" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import ProdMenu from "@/components/ProdMenu";
import "animate.css";
export default {
  name: "ApplicationScene",
  components: {
    Breadcrumb,
    ProdMenu,
  },
  data() {
    return {
      breadcrumbList: [
        { id: 1, path: "/", label: "ShuangzhaoDB" },
        { id: 2, path: "/product", label: "产品" },
        { id: 3, path: "", label: "应用场景" },
      ],
      sceneList: [
        {
          id: 1,
          title: "实时数仓",
          imgUrl: require("@/assets/images/application-scene1.png"),
        },
        {
          id: 2,
          title: "实时决策",
          content:
            "交易反欺诈平台采用双引擎模式保证客户的交易安全。其中业务规则引擎采用ShuangzhaoDB打造，主要用于金融交易的事中分析。",
          imgUrl: require("@/assets/images/application-scene2.png"),
        },
        { id: 3, title: "实时营销" },
      ],
      extraList: [
        {
          id: 11,
          imgUrl: require("@/assets/images/application-scene3.png"),
          list: [
            {
              id: 111,
              icon: "el-icon-star-on",
              content: "为合作商家提供交易监控",
            },
            {
              id: 112,
              icon: "el-icon-star-on",
              content: "根据用户参与进度进行激励",
            },
            {
              id: 113,
              icon: "el-icon-star-on",
              content: "针对不同用户设定不同的任务",
            },
            {
              id: 114,
              icon: "el-icon-star-on",
              content: "APP、网银、电话、邮件、社交媒体",
            },
          ],
        },
        {
          id: 12,
          imgUrl: require("@/assets/images/application-scene4.png"),
          list: [
            {
              id: 121,
              icon: "el-icon-star-on",
              content: "理财产品",
            },
            {
              id: 122,
              icon: "el-icon-star-on",
              content: "消费折扣券",
            },
            {
              id: 123,
              icon: "el-icon-star-on",
              content: "信贷产品",
            },
            {
              id: 124,
              icon: "el-icon-star-on",
              content: "保险产品",
            },
            {
              id: 125,
              icon: "el-icon-star-on",
              content: "旅游项目（周边游）",
            },
          ],
        },
        {
          id: 13,
          imgUrl: require("@/assets/images/application-scene5.png"),
          list: [
            {
              id: 131,
              icon: "el-icon-star-on",
              content: "周边合作商户推荐",
            },
            {
              id: 132,
              icon: "el-icon-star-on",
              content: "积分兑换题型",
            },
            {
              id: 133,
              icon: "el-icon-star-on",
              content: "旅游景点推送",
            },
            {
              id: 134,
              icon: "el-icon-star-on",
              content: "出行服务",
            },
          ],
        },
      ],
      previewPicList: [],
    };
  },
  created() {
    this.previewPicList.unshift(
      require("@/assets/images/application-scene6.png"),
      require("@/assets/images/application-scene7.png")
    );
    this.sceneList.map((item) => {
      if (item.imgUrl) this.previewPicList.push(item.imgUrl);
    });
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/product.less";
@import "~@/styles/variables.less";
.el-backtop {
  color: @themeRed;
  &:hover {
    background-color: rgba(245, 235, 230, 0.75);
  }
}
section {
  .redis-problems {
    padding-bottom: 15px;
    color: #767676;
    .point {
      padding-right: 16px;
    }
  }
  p {
    padding-bottom: 15px;
  }
}
section {
  table,
  tr th,
  tr td {
    border: 1px solid #ccc;
  }
  table {
    box-sizing: border-box;
    width: 100%;
    border-radius: 6px;
    border-spacing: 0;
    margin: 5px 0 20px;
    text-align: left;
    color: #767676;
    thead {
      color: #555;
    }
    tr th,
    tr td {
      border-left: 0;
      border-top: 0;
      padding: 5px 10px;
      &:last-of-type {
        border-right: 0;
      }
    }
    tbody tr:last-of-type th,
    tr:last-of-type td {
      border-bottom: 0;
    }
  }
  .optional-component {
    h4 {
      padding-bottom: 15px;
      color: #555;
    }
  }
  .el-image {
    margin-top: 16px;
  }
  .tips {
    margin: 10px;
    text-align: center;
    font-size: 12px;
    color: #d04229;
  }
}
.marketing {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 15px;

  img {
    height: 250px;
  }
  li {
    padding: 8px 0;
    .icon {
      color: @themeBlue;
      margin-right: 10px;
    }
  }
}
@media screen and(max-width:991px) {
  .el-backtop {
    display: none;
  }
  .marketing {
    img {
      height: 130px;
    }
    li {
      padding: 0;
    }
  }
  table {
    font-size: 12px;
  }
}
</style>
